import React, { useRef, useEffect, useState, Suspense } from "react";
import Webcam from "react-webcam";
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import * as cam from "@mediapipe/camera_utils";
import html2canvas from "html2canvas";
import Header from "./Header";
import Footer from "./Footer";
import './App.css';
import emailjs from "emailjs-com";

const App = () => {

  //Disable Right Click on Browser
  document.oncontextmenu = document.body.oncontextmenu = function () { return false; }


  // Refs for webcam and canvas elements
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  // State variables
  const [imageURL, setimageURL] = useState("");
  const [load, setLoad] = useState(false);
  const [webcamVisible, setWebcamVisible] = useState(false);
  const [snapshotURL, setSnapshotURL] = useState("");
  const [userEmail, setUserEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [backgrounds, setBackgrounds] = useState([]);
  const [image, setImage] = useState(new Image());

  // Fetch backgrounds from JSON file
  useEffect(() => {
    fetch('https://visitmp.evertizsolutions.com/backgrounds/index.php')
      .then(response => response.json())
      .then(data => setBackgrounds(data))
      .catch(error => console.error('Error fetching background images:', error));
  }, []);

  // Display the Segmented video on top of background
  const onResults = async (results) => {
    const img = document.getElementById('vbackground');
    if (!img) return;
    const imgWidth = img.width;
    const imgHeight = img.height;
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;
    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext('2d');
    canvasCtx.save();

    // Calculate the cropping percentages based on viewport width
    const viewportWidth = window.innerWidth;
    let leftCropPercentage = 0.025; // 2.5%
    let rightCropPercentage = 0.025; // 2.5%
    let topCropPercentage = 0.04; // 4%
    let bottomCropPercentage = 0.04; // 4%

    if (viewportWidth <= 800) {
      leftCropPercentage = 0.03; // 3%
      rightCropPercentage = 0.03; // 3%
      topCropPercentage = 0.018; // 1.8%
      bottomCropPercentage = 0.018; // 1.8%
    }

    // Calculate the position to center the segmented image on the canvas
    const cropWidth = results.image.width * (1 - leftCropPercentage - rightCropPercentage);
    const cropHeight = results.image.height * (1 - topCropPercentage - bottomCropPercentage);
    const cropX = (results.image.width - cropWidth) / 2;
    const cropY = (results.image.height - cropHeight) / 2;
    const centerX = (canvasElement.width - cropWidth) / 2;
    const centerY = (canvasElement.height - cropHeight) / 2;

    // Clear the canvas
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

    // Draw the segmented image centered on the canvas with cropping
    canvasCtx.drawImage(
      results.image,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      centerX,
      centerY,
      cropWidth,
      cropHeight
    );

    // Only overwrite existing pixels.
    canvasCtx.globalCompositeOperation = 'destination-atop';
    canvasCtx.drawImage(
      results.segmentationMask,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      centerX,
      centerY,
      cropWidth,
      cropHeight
    );

    // Only overwrite missing pixels.
    canvasCtx.globalCompositeOperation = 'destination-over';
    canvasCtx.drawImage(img, 0, 0, canvasElement.width, canvasElement.height);

    canvasCtx.restore();
    setLoad(true);
  };

  // Perform Segmentation on live webcam
  useEffect(() => {
    const selfieSegmentation = new SelfieSegmentation({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
      },
    });
    selfieSegmentation.setOptions({
      modelSelection: 1,
    });
    selfieSegmentation.onResults(onResults);

    let camera;

    if (webcamVisible && webcamRef.current) {
      camera = new cam.Camera(webcamRef.current.video, {
        onFrame: async () => {
          await selfieSegmentation.send({ image: webcamRef.current.video });
        },
      });
      camera.start();
    }

    return () => {
      if (camera) {
        camera.stop();
      }
      selfieSegmentation.close();
    };
  }, [webcamVisible]);

  // Update background image when imageURL changes
  useEffect(() => {
    const img = new Image();
    img.src = imageURL;
    img.onload = () => {
      setImage(img);
    };
  }, [imageURL]);

  // Functions for opening and closing modals
  function openInstructionsModal() {
    let instructionsModal = document.getElementById('instructionsModal');
    instructionsModal.style.display = "flex";
  }

  function openBackgroundsModal() {
    let backgroundsModal = document.getElementById('backgroundsModalWrapper');
    backgroundsModal.style.display = "flex";
    setWebcamVisible(true);
  }

  const openOutputModal = async () => {
    let outputModal = document.getElementById('outputModalWrapper');
    outputModal.style.display = "block";
  }

  function closeInstructionsModal() {
    let instructionsModal = document.getElementById('instructionsModal');
    instructionsModal.style.display = "none";
  }

  function closeWebcamModal() {
    setWebcamVisible(false);
    let webcamModal = document.getElementById('webcamModalWrapper');
    webcamModal.style.display = "none";
    console.log("closeWebcamModal is called");
  }

  function closeOutputModal() {
    let outputModal = document.getElementById('outputModalWrapper');
    outputModal.style.display = "none";
  }

  function closeBackgroundsModal() {
    let backgroundsModal = document.getElementById('backgroundsModalWrapper');
    backgroundsModal.style.display = "none";
  }

  const captureSnapshot = async () => {
    try {
      const canvas = await html2canvas(document.getElementById("video"));
      const compressedDataURL = await compressImage(canvas.toDataURL(), 1500);
      setSnapshotURL(compressedDataURL);
      openOutputModal();
    } catch (error) {
      console.error("Error capturing and compressing snapshot:", error);
    }
  };

  // Function to compress the image
  const compressImage = async (dataURL, maxSizeInKB) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = dataURL;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const maxDimension = Math.sqrt(maxSizeInKB * 1024);
        let width = image.width;
        let height = image.height;

        if (width > maxDimension) {
          height *= maxDimension / width;
          width = maxDimension;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, width, height);

        canvas.toBlob((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(reader.result);
          };
        }, "image/jpeg", 1); // Adjust quality as needed
      };
    });
  };

  // Function to send the email
  function sendCanvasAsAttachment() {
    if (isValidEmail) {
      const base64 = snapshotURL;
      emailjs.send('', '', {
        content: base64,
        mail_to: userEmail,
      }, "")
        .then(() => {
          alert('Image was Successfully sent!');
          window.location.replace("");
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    } else {
      alert("Please enter a valid email address.");
    }
  }

  // Function to validate email
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  // Handle email input change
  const handleEmailChange = (event) => {
    const email = event.target.value;
    setUserEmail(email);
    setIsValidEmail(validateEmail(email));
  }

  return (
    <>
      <Header />
      <main>
        <video className="heroVideo" src="../assets/mptourism hero video.mp4" autoPlay muted loop></video>
        <video className="heroVideoPhone" src="../assets/mptourism hero video phone.mp4" autoPlay muted loop></video>
        <div className="overlay-wrapper">
          <p>Travel has always been a gateway to new experiences, a means to broaden horizons, and a way to connect with the world. Whether you dream of standing amidst the timeless ruins of Khajuraho, losing yourself in the lush jungles of Kanha, or savoring the tranquility of Pachmarhi's hill stations, embark on a virtual journey through the heart of Incredible India, Madhya Pradesh!</p>
          <button onClick={openInstructionsModal}>Click to Start</button>
        </div>
        <div className="modal-wrapper" id="instructionsModal">
          {/* Close button */}
          <a onClick={closeInstructionsModal}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4-9.4-24.6-9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg></a>
          <div className="instructionsModalDiv1">
            <p>1. Choose Your Background</p>
            <p>2. Position Your Face</p>
            <p>3. Capture</p>
            <p>4. Retake / Print</p>
          </div>
          <div className="instructionsModalDiv2">
            <img src="../assets/Start.webp" onClick={openBackgroundsModal} alt="Start" />
            <p>Start</p>
          </div>
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          {webcamVisible && (
            <div className="modal-wrapper" id="webcamModalWrapper">
              {/* Close button */}
              <a onClick={closeWebcamModal}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4-9.4-24.6-9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg></a>
              <div className="input-wrapper">
                <div className="videoContainer">
                  <div className="videoContent">
                    <div className="video" id="video" >
                      <Webcam
                        ref={webcamRef}
                        style={{
                          display: "none",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <canvas
                        ref={canvasRef}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      ></canvas>
                    </div>
                  </div>
                  <button onClick={captureSnapshot}>Capture Snapshot</button>
                </div>
                <div className="backgroundContainer">
                  <div className="backgrounds">
                    <img id="vbackground" src={imageURL} alt="The Screen" className="background" />
                  </div>
                  <button onClick={openBackgroundsModal}>Choose Background</button>
                </div>
              </div>
            </div>
          )}
        </Suspense>
        <div className="modal-wrapper" id="backgroundsModalWrapper">
          {/* Close button */}
          <a onClick={closeBackgroundsModal}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4-9.4-24.6-9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg></a>
          {backgrounds.map((background, index) => (
            <img
              key={index}
              src={background.src}
              className={background.class}
              alt={background.alt}
              loading="lazy"
              onClick={() => {
                setimageURL(background.src);
                closeBackgroundsModal();
              }}
            />
          ))}
        </div>
        <div className="modal-wrapper" id="outputModalWrapper">
          <div className="output-wrapper" id="output">
            <img src={snapshotURL} alt="Snapshot" />
            <input
              type="email"
              id="emailInput"
              className="inputDisplay"
              placeholder="Enter your email"
              required
              value={userEmail}
              onChange={handleEmailChange}
            />
            <div className="output-btns">
              <input id="download"
                type="submit"
                className="inputDisplay"
                onClick={sendCanvasAsAttachment}
                value="Download"
              />
              <button onClick={closeOutputModal}>Retake Snapshot</button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
export default App;
